.inner-banner-wrap .inner-baner-container {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    text-align: center;
    position: relative;
    padding-top: 40px;
    background-image: url("../../assets/images/feature/anepal.jpeg");
}

.inner-banner-wrap .inner-baner-container:before {
    background-color: #151515;
    content: "";
    display: block;
    opacity: 0.65;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

  

.inner-banner-wrap .inner-baner-container .inner-banner-content {
    padding: 90px 0 75px 0;
    position: relative;
    color: #ffffff;
}

.inner-banner-wrap .inner-baner-container .inner-banner-content .page-title {
    font-size: 50px;
    color: #ffffff;
    line-height: 150%;
    text-align: center;
    font-weight: bolder;
    text-transform: uppercase;
    margin-bottom: 0;
}

.navbar-link {
    background-color: rgba(0, 0, 0, 0.829);
}

.navbar-link .breadcrumb {
    padding: 0;
    margin: 0;
}

.navbar-link .breadcrumb .breadcrumb-item {
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}

.navbar-link .breadcrumb a {
    color: #ffffff;
    font-size: 14px;
    text-decoration: none;
    cursor: pointer;
}