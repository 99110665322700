.filter_box {
  padding: 20px 30px 30px;
  border: 1px solid #e7e6e6;
}


.filters {
  padding: 10px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .filters {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }

  .filter_box {
    padding: 15px;
  }
}

.filter_box .accordion-item {
  border-bottom: 1px solid #e7e6e6 !important;
}

.filter_box .accordion:last-child .accordion-item {
  border: none !important;
}
.additional-text {
  font-size: 1.5rem; /* Adjust size as needed */
  color: #fff; /* Change color as needed */
  margin-top: 0.5rem; /* Add some spacing */
}

.filter_box .accordion-item,
.filter_box .accordion-item button {
  background: none;
  border: none;
  border-radius: 0;
}
.filter_box .accordion-item button {
  border: none !important;
  font-size: 16px;
  font-weight: 600;
  padding: 16px 0px;
  box-shadow: none !important;
}

.accordion-item button:focus {
  border: none;
  outline: none;
  box-shadow: unset;
}

.filter_box .accordion-item .accordion-body {
  padding: 0;
  margin-bottom: 15px;
}

.filter_box .accordion-item .accordion-body .form-check {
  margin-bottom: 10px;
}

.filter_box .accordion-item .accordion-body .form-check-input {
  width: 1.2em;
  height: 1.2em;
}

.filter_box .accordion-item .accordion-body .form-check-label {
  margin-left: 8px;
  margin-top: 5px;
}

.filters {
  position: sticky;
  top: 55px;
}

/* details  */

/* .image-gallery-content .image-gallery-slide .image-gallery-image {
  max-height: 100% !important;
} */
/* slider */
.tour_details .image-gallery-thumbnail {
  width: 12.3%;
}
.tour_details .image-gallery-thumbnail:hover,
.tour_details .image-gallery-thumbnail.active {
  border-color: var(--primaryClr);
}

/* details */
.tour_details .nav_bars {
  background: #f14b361a;
  padding: 8px;
}
.tour_details .nav_bars .nav-link.active {
  background: var(--primaryClr);
  color: white;
}

.tour_details .nav_bars .nav-link {
  font-size: 16px;
  font-weight: bold;
  padding: 10px 0px;
  color: black;
}

.tour_details .nav_bars .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

/* side bar  */
.tour_details .card-info {
  border: none;
  background: #f5f5f6;
}

.tour_details .list-group-item {
  padding: 16px 0px;
  border: none;
  font-size: var(--bodyText);
  background: unset;
  line-height: 24px;
  border-bottom: 1px solid gainsboro;
}
.tour_details .list-group-item i {
  color: var(--primaryClr);
  font-weight: bold;
}
.tour_details .list-group-item strong {
  font-weight: 800;
}

.tour_details .tour_plan  .accordion, .tour_details .tour_plan .accordion-item{
  border: none;
}

.tour_details .tour_plan .accordion-header button{
  background-color: #f5f5f6;
  border: none;
  border-radius: 8px;
}
