/* src/components/modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    position: relative; /* Position relative to allow absolute positioning of close button */
    max-width: 600px; /* Set a max width */
    margin: 0 auto; /* Center the modal */
  }
  
  .modal-image {
    max-width: 100%; /* Image will not exceed the modal width */
    height: auto; /* Maintain aspect ratio */
    border-radius: 8px; /* Rounded corners for the image */
  }
  
  .close-button {
    margin-top: 10px;
    padding: 5px 10px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .close-button:hover {
    background: #0056b3; /* Darker blue on hover */
  }
  