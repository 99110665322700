.booking-section .form-control {
  background-color: #f5f5f6;
  border-color: #f5f5f6;
  color: black;
  padding: 10px;
}

.booking-section .form-control:focus {
  border-color: var(--primaryClr);
  outline: none;
  box-shadow: unset;
}

.booking-section label {
  color: var(--textclr);
  font-weight: 600;
}

.react-datepicker-wrapper {
  width: 100%;
}



 .card-info {
  border: none;
  background: #f5f5f6;
}

 .list-group-item {
  padding: 16px 0px;
  border: none;
  font-size: var(--bodyText);
  background: unset;
  line-height: 24px;
  border-bottom: 1px solid gainsboro;
}
 .list-group-item i {
  color: var(--primaryClr);
  font-weight: bold;
}
 .list-group-item strong {
  font-weight: 800;
}

