.feature-section {
    padding: 40px 0px;
    overflow: hidden;
}

.feature-section .slick-active {
    border: none !important;
}


.section-title {
    font-size: 2rem; /* Adjust size as needed */
    font-weight: bold;
    color: #333; /* Adjust color as needed */
    text-align: center;
    margin-bottom: 20px;
  }
  
.feature-section .card {
    border: none !important;
    text-align: center;
    padding: 0px 14px;
    margin-bottom: 14px;
}

.feature-section .card img {
    width: 75px;
    margin: 0px auto;
    margin-bottom: 14px;
}

.feature-section .card .card-title {
    font-size: var(--subTitle);
    padding: 12px 0px;
    font-weight: bold;
}

.feature-section .card .card-text {
    font-size: var(--bodyText);
    line-height: 22px;
    color: var(--textclr);
}

.feature-section .card:hover .card-title {
    color: var(--primaryClr);
}

/* Custom Arrow Styles */
.custom-arrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 24px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    padding: 10px;
    border-radius: 50%;
    cursor: pointer;
    z-index: 100;
}

.left-arrow {
    left: -40px;
}

.right-arrow {
    right: -40px;
}

.custom-arrow:hover {
    background: rgba(0, 0, 0, 0.8);
}
