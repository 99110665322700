.blog-details {
  padding: 2rem 0;
}

.blog-image {
  width: 100%;
  height: auto;
  border-radius: 8px; /* Optional: Rounded corners for the image */
}

h1 {
  margin-bottom: 1.5rem;
  font-size: 2rem; /* Larger title */
  font-weight: bold;
}

.blog-content {
  line-height: 1.6;
  color: #555; /* Light text color for better readability */
  font-size: 1rem;
  text-align: justify;
}

/* Adjustments for responsive layout */
@media (max-width: 768px) {
  .blog-details {
    padding: 1rem 0;
  }

  .blog-image {
    margin-bottom: 1rem; /* Space below the image on smaller screens */
  }

  h1 {
    font-size: 1.5rem; /* Smaller title for smaller screens */
  }
}
