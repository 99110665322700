@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Libre+Baskerville:ital,wght@0,400;0,700;1,400&display=swap');


@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");
@import "bootstrap/dist/css/bootstrap.min.css";

:root {
  --primaryClr: #fe4e37;
  --secondaryClr: #fffaf9;
  --bgClr: #f8f9fd;
  --white: #ffffff;
  --black: black;
  --textclr: #4f5e71;

  --smallText: 12px;
  /* changes */
  --bodyText: 16px;
  --subTitle: 18px;
  --heading: 26px;
  --fontFamily: "Lato", sans-serif;
  --fontFamily1: "Libre Baskerville", serif;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: var(--fontFamily);
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.937rem;
  padding-right: 0.937rem;
  max-width: 1200px;
}

.body-text,p {
  font-size: var(--bodyText);

  color: var(--textclr);
  line-height: 26px;
}

.title {
  font-size: var(--subTitle);
  line-height: 26px;
  font-weight: bold;
}


.font-bold {
	font-weight: 800;
}