.partners-section {
    padding: 40px 0;
    text-align: center;
}

.partner-card {
    border: none !important;
}

.partner-card img {
    width: 200px; /* Set the desired width */
    height: auto; /* Maintain aspect ratio */
    margin: 0 auto; /* Center the image */
}

.partner-description {
    font-size: var(--bodyText);
    line-height: 1.5;
    color: var(--textclr);
    margin-top: 20px;
}
