footer {
  background: black;
}

footer h4 {
  font-size: var(--subTitle);
  color: whitesmoke;
  line-height: 150%;
  font-weight: bold;
  padding: 0 0 18px 0;
  margin: 0;
}

footer p,
footer a,
footer i,
footer span,
footer .copyright {
  margin: 0;
  font-size: var(--bodyText);
  color: whitesmoke;
  line-height: 150%;
  font-weight: bold;
  text-decoration: none;
}

footer .quick_link .list-group .list-group-item {
  background: none;
  padding: 0;
  margin-bottom: 5px;
  font-size: var(--bodyText);
  line-height: 150%;
  color: #b4b4b4;
  font-weight: bold;
  border: none;
}

footer .quick_link .list-group .list-group-item:hover a {
  color: var(--white);
}

footer .quick_link .list-group .list-group-item:first-child {
  padding-top: 0;
}

footer .location .d-flex {
  margin-bottom: 5px;
}

footer .bdr {
  border-top: 1px solid #f5f5f578;
}

#back-top {
  opacity: 0;
  z-index: 99999;
  font-size: 26px;
  color: white;
  line-height: 47px;
  text-align: center;
  position: fixed;
  right: 20px;
  bottom: 50px;
  width: 45px;
  height: 45px;
  cursor: pointer;
  border-radius: 5px;
  transition: all 0.6s ease;
  transform: translateY(40px);
  background: var(--primaryClr);
}

#back-top.active {
  opacity: 1;
  transition: all 0.6s ease;
  transform: translateY(-20px);
}


/* Wrapper for both WhatsApp and Panda icons */
.floating-icons {
  position: fixed;
  bottom: 110px; /* Adjust based on the WhatsApp position */
  right: 20px;
  display: flex;
  align-items: center;
  gap: 10px; /* Adds space between WhatsApp and Panda */
}

/* WhatsApp Icon */
.whatsapp-icon {
  width: 45px;
  height: 45px;
  cursor: pointer;
}

.whatsapp-icon img {
  width: 100%;
  height: auto;
}

.panda-icon {
  width: 90px; /* Increased from 45px */
  height: 90px; /* Increased from 45px */
}

.panda-icon img {
  width: 100%;
  height: auto;
}